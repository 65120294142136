import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dashboard, Logout, Notifications, Person } from '@mui/icons-material';
import { AppBar, Avatar, Badge, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import Images from 'assets/images/Images';
import Colors from 'assets/style/Colors';
import { CircleLoading } from 'components/loaders/Loaders';
import { useAuth } from 'context/UseContext';
import { ErrorToaster } from 'components/toaster/Toaster';

function DropDown({ anchorEl, openDropdown, handleClose }) {

  const { userLogout } = useAuth();
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '.MuiSvgIcon-root': {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={() => navigate('/')}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        Dashboard
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile')}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => userLogout()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )
}

function Header() {

  const { userDetail } = useAuth();

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const [notifyAnchor, setNotifyAnchor] = useState(null);
  const openNotificationList = Boolean(notifyAnchor);

  // *For Notification
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(2);

  // *For Get Notifications
  const getNotifications = async () => {
    setLoading(true)
    try {
      // const { responseCode, data } = await NotificationServices.getNotifications()
      // if (responseCode === 200) {
      // }
      setNotifications([])
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AppBar elevation={0} position="static" sx={{ bgcolor: Colors.white, }}>
      <Toolbar sx={{ px: { xl: 6, xs: 3 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="img" src={Images.logo} sx={{ height: '50px' }} />
          <Typography variant="h3" sx={{ color: Colors.textPrimary }}>
            HASHTAG
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton aria-label="notification" sx={{ mx: 1 }} onClick={(e) => { setNotifyAnchor(e.currentTarget); getNotifications() }}>
            <Badge badgeContent={notificationCount} color="danger">
              <Notifications />
            </Badge>
          </IconButton>

          {/* ========== Notification Dropdown ========== */}
          <Menu
            anchorEl={notifyAnchor}
            open={openNotificationList}
            onClose={() => { setNotifyAnchor(null); setNotificationCount(0) }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: '390px',
                overflow: 'auto',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                borderRadius: '12px',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <Box sx={{ px: '16px', py: 1 }}>
              <Typography variant="subtitle1">
                Notifications
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
              {loading ? (
                <CircleLoading />
              ) : (
                notifications?.length > 0 ? (
                  notifications.map((item, index) => (
                    <MenuItem key={index} onClick={() => setNotifyAnchor(null)} sx={{ borderBottom: `1px solid ${Colors.smokeyGrey + '4D'}`, bgcolor: index >= notificationCount ? 'transparent' : Colors.textPrimary + '40', py: 2, my: 0.3, gap: '10px', alignItems: 'start', ':hover': { bgcolor: index >= notificationCount ? 'transparent' : Colors.textPrimary + '40' } }}>
                      <Box
                        component="img"
                        src={''}
                        sx={{
                          objectFit: 'contain',
                          width: '50px'
                        }}
                      />
                      <Box sx={{ whiteSpace: 'break-spaces' }}>
                        <Typography variant="body2" >
                          {item.title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))) : (
                  <Typography variant="body1" sx={{ py: 2, px: 1, textAlign: 'center' }} >
                    Your have no notification.
                  </Typography>
                ))}
            </Box>
          </Menu>

          <Box sx={{ cursor: 'pointer' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Avatar
              src={userDetail?.image}
              sx={{
                width: 30,
                height: 30,
                border: `1px solid ${Colors.primary}`,
                '.MuiAvatar-img': { objectFit: 'contain' }
              }}
            />
          </Box>
        </Box>
        <DropDown anchorEl={anchorEl} openDropdown={openDropdown} handleClose={() => setAnchorEl(null)} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;