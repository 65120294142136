import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Box, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Navigations from 'Navigation';
import Colors from 'assets/style/Colors';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAuth } from 'context/UseContext';

function Nav() {

  const { user, userDetail } = useAuth();
  const { pathname } = useLocation();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  return (
    <Box sx={{ bgcolor: Colors.tertiary, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
      <Box sx={{ display: 'flex', pt: 4, pb: 2, px: 2 }}>
        <Avatar
          src={userDetail?.image}
          sx={{
            mr: 1,
            width: 40,
            height: 40,
            border: `1px solid ${Colors.primary}`,
            '.MuiAvatar-img': { objectFit: 'contain' }
          }}
        />
        <Box sx={{ width: 1 }}>
          <Typography variant="subtitle1" className="text-truncate" sx={{ color: Colors.white }}>
            {userDetail?.username ? userDetail?.username : user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: Colors.grey }}>
            {userDetail?.email ? userDetail?.email : user?.email}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ width: '80%', mx: 'auto', my: 1 }} />
      <Box sx={{ height: 'calc(100vh - 178px)', overflowY: 'auto' }}>
        <List>
          {Navigations.map((item, index) => {
            const isSelected = item.path === pathname ? true : false
            if (item?.type) {
              if (item?.type != user?.type) { return '' }
            }
            return (
              <Fragment key={index}>
                <ListItemButton key={index}
                  component={item.path ? Link : 'div'}
                  to={item.path ?? ''}
                  onClick={() => item?.children?.length > 0 && handleCollapse(item.label)}
                  sx={{
                    mt: 1.5,
                    background: isSelected ? Colors.charcoalBlack : "",
                    ".MuiSvgIcon-root": {
                      color: isSelected ? Colors.white : Colors.textSecondary,
                    },
                    '&:hover': {
                      background: Colors.charcoalBlack,
                      ".MuiSvgIcon-root": {
                        color: Colors.white
                      },
                      ".MuiTypography-root": {
                        color: Colors.white
                      },
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '35px' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1"
                        sx={{
                          color: isSelected ? Colors.white : Colors.textSecondary,
                          fontWeight: isSelected ? 'bold' : '500'
                        }}>
                        {item.label}
                      </Typography>
                    }
                  />
                  {item?.children &&
                    (expand.indexOf(item.label) !== -1 ?
                      <ExpandLess sx={{ color: Colors.textSecondary }} /> : <ExpandMore sx={{ color: Colors.textSecondary }} />)
                  }
                </ListItemButton>
                {item?.children &&
                  <Collapse in={expand.indexOf(item.label) !== -1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.children.map((child, i) => (
                        <ListItemButton key={i}
                          component={child.path ? Link : 'div'}
                          to={child.path}
                          sx={{
                            mt: 0.5,
                            pl: '50px',
                            background: child.path === pathname ? Colors.charcoalBlack : "",
                            ".MuiSvgIcon-root": {
                              color: child.path === pathname ? Colors.white : Colors.textSecondary,
                            },
                            '&:hover': {
                              background: Colors.charcoalBlack,
                              ".MuiSvgIcon-root": {
                                color: Colors.white
                              },
                              ".MuiTypography-root": {
                                color: Colors.white
                              },
                            }
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 30, '.MuiSvgIcon-root': { fontSize: "16px" } }}>
                            {child.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography type="body2"
                                sx={{
                                  fontSize: 13,
                                  fontWeight: child.path === pathname ? "bold" : "normal",
                                  color: child.path === pathname ? Colors.white : Colors.textSecondary
                                }}>
                                {child.label}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                }
              </Fragment>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

function Sidebar() {

  return (
    <Fragment>

      {/* <Drawer
        anchor="left"
        open={false}
        sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { width: 250 } }}
      >
        <Nav />
      </Drawer> */}

      <Box sx={{ display: { xs: 'none', md: 'block' }, width: 320 }}>
        <Nav />
      </Box>

    </Fragment>
  );
}

export default Sidebar;