import { Computer, LocalOffer, Person, Storefront, Loyalty, Inventory, LocalActivity, Forum } from "@mui/icons-material"

const Navigations = [
  {
    label: 'Dashboard',
    icon: <Computer />,
    path: '/'
  },
  {
    label: 'Product Management',
    icon: <Storefront />,
    children: [
      {
        label: 'Products',
        icon: <LocalOffer />,
        path: '/product-management/products'
      }
    ]
  },
  {
    label: 'Order Management',
    icon: <Inventory />,
    children: [
      {
        label: 'Orders',
        icon: <Inventory />,
        path: '/order-management/orders'
      },
    ]
  },
  {
    label: 'Sale Management',
    icon: <Inventory />,
    path: '/sale-management'
  },
  {
    label: 'Manage Reviews',
    icon: <Inventory />,
    path: '/review-management'
  },
  {
    label: 'Vouchers',
    icon: <LocalActivity />,
    path: '/vouchers'
  },
  {
    label: 'Transactions',
    icon: <LocalActivity />,
    path: '/transactions'
  },
  {
    label: 'Fulfillment',
    icon: <LocalActivity />,
    path: '/fulfillment'
  },
  {
    label: 'Membership Plan',
    icon: <Loyalty />,
    children: [
      {
        label: 'Activate Membership',
        icon: <Loyalty />,
        path: '/membership-plan/activate-membership'
      },
      {
        label: 'Update Membership',
        icon: <Loyalty />,
        path: '/membership-plan/update-membership'
      },
    ]
  },
  {
    label: 'Invoice Management',
    icon: <Inventory />,
    path: '/invoice-management'
  },
  {
    label: 'Chat',
    icon: <Forum />,
    path: '/chat'
  },
  {
    label: 'Profile',
    icon: <Person />,
    path: '/profile'
  },
]

export default Navigations