import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import Colors from './assets/style/Colors';
import { ToasterContainer } from './components/toaster/Toaster';
import AuthRoutes from './routes/AuthRoutes';
import DashboardRoutes from './routes/DashboardRoutes';
import WebLayout from './layout/web';
import DashboardLayout from './layout/dashboard';
import ScrollToTop from './hooks/ScrollToTop';
import { useAuth } from 'context/UseContext';
import Pusher from 'pusher-js';
import { baseUrl } from 'config/axios';

function App() {

  const { user } = useAuth();

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.secondary,
        contrastText: Colors.white,
      },
      danger: {
        main: Colors.danger,
        contrastText: Colors.white,
      },
    },
    typography: {
      h3: {
        fontSize: 28,
        fontWeight: 700,
      },
      h5: {
        fontSize: 22,
        fontWeight: 700,
      },
      h6: {
        fontSize: 20,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 500
      },
      body1: {
        fontSize: 14,
        fontWeight: 500
      },
      body2: {
        fontSize: 12,
        fontWeight: 400
      },
      caption: {
        fontSize: 10,
        fontWeight: 300
      },
    },
  });

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'ap2',
      channelAuthorization: {
        endpoint: baseUrl + 'api/channelAuthorization',
        headers: { 'Authorization': `Bearer ${user?.token}` }
      }
    });

    const channel = pusher.subscribe('private-notification-channel.' + user?.id);
    channel.bind('notification.received', (data) => {
      console.log('pusher message =>', data);
    });

    return () => {
      pusher.unsubscribe('private-notification-channel.' + user?.id);
      pusher.disconnect();
    };
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Toaster ========== */}
        <ToasterContainer />

        <Routes>

          <Route element={user?.token ? <Navigate to="/" /> : <WebLayout />} >
            {AuthRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.token ? <DashboardLayout /> : <Navigate to="/login" />} >
            {DashboardRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Scroll To Top ========== */}
        <ScrollToTop />

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
